import React, { useEffect, useState, useRef } from "react"
import { Helmet } from "react-helmet"
import Seo from "../components/seo"
import { Container, Row, Col } from "react-bootstrap"
import { useQuery } from "@apollo/client"
import gql from "graphql-tag"
import TeamDetailInfo from "../components/team-detail-info/team-detail-info"
import Breadcrumbs from "../components/breadcrumbs/breadcrumbs"
import Layout from "../components/layout"
import HomeBanner from "../components/home-banner/home-banner"
import logoBlack from "../images/svg/logo.svg"
import NotFoundPage from "../pages/404"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import { useStaticQuery, graphql } from "gatsby"
import TeamBanner from "../components/home-banner/team-banner"
import OtherNewsSales from "../components/news-sales/other-news-sales"
import CareerDetailInfo from "../components/team-detail-info/career-detail-info"
import OtherCareerSlider from "../components/home-properties/other-career-slide"
import { toCapitalize } from "@components/common/utils";
const CareerDetail = (props) => {
  const GET_CAREER_DETAILS = gql`
  query GetCareer($URL: String!) {
    careers(where: { URL: $URL }) {
      id
      imagetransforms
      Job_Title
      Job_Detail
      id
      URL
      Department
      Branch{
        Office_Name
      }
      Meta_Title
      Meta_Description
      Video_Url
      Tail_Image{
        url
        alternativeText
      }
  
     
    }
  }
`
  const { loading, error, data } = useQuery(GET_CAREER_DETAILS, {
    variables: { URL: props.slug },
    fetchPolicy: "no-cache"
  })
  let shareurl = ""
  if (typeof window !== "undefined") {
    shareurl = window.location.href ? window.location.href : ""
  }
  if (loading)
  return (
    <div className="static-news gql-loading-screen">
      <Container>
        <div className="gql-loading">
          <img
            className="logo-white loader-logo"
            src={logoBlack}
            alt="logo"
          />
        </div>
      </Container>
    </div>
  )

  return data?.careers?.length > 0 ? (
    <Layout
    searchtype={"office"}
    >
      {data?.careers?.map((Page, i) => {
        let processedImages = JSON.stringify({})
        if (Page?.imagetransforms?.Image_Transforms) {
          processedImages = Page.imagetransforms.Image_Transforms
        }
        const path = {
          pathname: `/about/${props?.alias}/${props?.slug}/`
        }
        return (
          <>
            <Seo
              title={
                Page ? `${Page?.Job_Title} in ${toCapitalize(Page?.Branch[0]?.Office_Name)}` : ""
              }
              location={path}
              description={
                Page
                  ? 
                  `Find the details about ${Page?.Job_Title} in ${toCapitalize(Page?.Branch[0]?.Office_Name)}. Apply for the ${Page?.Job_Title} or get in touch with us for assistance.`
                  : ""
              }
              image={Page?.Tail_Image?.url ? Page.Tail_Image.url : null}
            />
            <Helmet
              bodyAttributes={{
                class: `templates-blog-details-template career-page`,
              }}
            />

            <Breadcrumbs Page={Page?.Job_Title} alias={"estate-agent-jobs-in-wiltshire"} type={"details-page"} />
            <CareerDetailInfo data={Page} />
            <OtherCareerSlider URL={Page?.URL} 
              Title={`Other positions`}
              // Cap_Title={`Area Guides`}
               />
            {/* <OtherNewsSales URL={Page?.URL} /> */}
                    
          </>
        )
      })}
    </Layout>
  ) : (
    <Layout className="blog-page">
      <Router>
        <Switch>
          <Route component={NotFoundPage} />
        </Switch>
      </Router>
    </Layout>
  )

}

export default CareerDetail

