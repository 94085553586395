/**
 * External dependencies
 */
import React, {useState} from "react"
import { Row, Col, Container } from "react-bootstrap"
import CareerForm from "../../Forms/CareerForm"
import Modal from 'react-bootstrap/Modal';
/**
 * Internal dependencies
 */
import VideoPlaceholder from "../video-placeholder/video-placeholder"
import useTeamDetailInfo from "./use-team-detail-info"
import Button from "../button/button"
import "./styles/_index.scss"
import { toCapitalize } from "@components/common/utils";
import ContentBlock from "../content/Content";
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton
} from "react-share";
import {
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  WhatsappIcon
} from "react-share";
const CareerDetailInfo = (props) => {
  // let processedImages = JSON.stringify({})
  // if (props?.data?.imagetransforms?.Image_Transforms) {
  //   processedImages = props?.data?.imagetransforms.Image_Transforms
  // }
  const [showForm, setShowForm] = useState(false);
  const openform = () => {
          setShowForm(!showForm)
  }
    // Social share
      
    const shareurl = typeof window !== 'undefined' ? window.location.href : ''
    // const viewingBookingUrl = get(props, 'propertyData.extra.viewing_booking_url', false)

    const [Shareicons,setShareicons] = React.useState(false);

    const openShareicons = () => {
        setShareicons(true);
        if(Shareicons === true) {
        setShareicons(false);
        }
    }

    const trackerShare = (event) => {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
      'event': 'Share - social icons',
      'formType': event + ' - ' +shareurl,
      'formId': 'Share',
      'formName': 'Share',
      'formLabel': 'Share'
      });
  }
  // Social share
  let processedImages = JSON.stringify({})
  if (props?.data?.imagetransforms?.Tail_Image_Transforms) {
    processedImages = props?.data?.imagetransforms.Tail_Image_Transforms
  }
  return (
    <div className="career-details">
    <Container className="team-detail-info carrers_info">
      <Row className="g-0 justify-content-lg-between">
        <Col xs="12" lg="auto" className="team-detail-info__video carrers">
        {props?.data?.Video_Url?

<div className="area-guide-intro__video carrers d-lg-block position-absolute">
<iframe src={props?.data?.Video_Url}
className="videoask-frame"
allow="camera *; microphone *; autoplay *; encrypted-media *; fullscreen *; display-capture *;"
>
</iframe>
</div>
        :
          <VideoPlaceholder
            image={props?.data?.Tail_Image}
            // video="https://www.videoask.com/f48u0eo71"
            imagetransforms={processedImages}
            id={props?.data?.id}
            alt={props?.data?.Tail_Image?.alternativeText}
            imgname={"career.Tail_Image.detail"}
            playBtnOnRight={props?.data?.Video_Url ? true : false}
            title="Careers at Strakers"
            btnIcon="calendar"
            btnText="Apply for this job"
            onClick={openform}
            displayAsLink={false}
          />
        }
      
        </Col>

        <Col
          className="team-detail-info__content order-lg-first"
          xs="12"
          lg="auto"
        >
          <h1>{props?.data?.Job_Title}</h1>
          <p className="body-m">
          {props?.data?.Branch?.map((list, index) => (
                            <>
                              {toCapitalize(list.Office_Name)}
                              {index === props?.data?.Branch?.length - 1
                                ? ""
                                : ", "}
                            </>
                          ))}
          </p>
          <Row className="team-detail-info__cta mb-5 mt-5 mt-md-6 flex-column flex-md-row">
            <Col className="pb-2 mb-4 pe-md-4">
           
              <Button displayAsLink={false}  onClick={openform} color="yellow">
                <Button.Icon>
                  <i className="icon icon--mail" />
                </Button.Icon>
                <Button.Text>Apply for this job</Button.Text>
              </Button>
              <Modal className="teamdetails popupForm" backdrop="static" size="lg" show={showForm} onHide={() => { setShowForm(false) }}>
                                            <Modal.Header closeButton>
                                                <Modal.Title> <div className="form__head">
          <h4>Apply for this job</h4>
        </div>
</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body> 
                                                <CareerForm />  
                                            </Modal.Body>

                                        </Modal>

            </Col>
            <Col className="ps-md-4">
              <Button onClick={openShareicons} displayAsLink={false}>
                <Button.Icon>
                  <i className="icon icon--share" />
                </Button.Icon>
                <Button.Text>Share this job</Button.Text>
              </Button>
              {Shareicons &&
                                            <div onClick={openShareicons} className="property-share-social-icons2 career mobile-details-socila-share">
                                            <FacebookShareButton onClick={()=>trackerShare('FacebookShareButton')} url={shareurl} className="my-share-button facebook-share">
                                                <FacebookIcon size={32} round={false} borderRadius={`10`} />
                                            </FacebookShareButton>
                                            <TwitterShareButton onClick={()=>trackerShare('TwitterShareButton')} url={shareurl} className="my-share-button twitter-share">
                                                <TwitterIcon size={32} round={false} borderRadius={`10`} />
                                            </TwitterShareButton>
                                            <LinkedinShareButton onClick={()=>trackerShare('LinkedinShareButton')} url={shareurl} className="my-share-button linkedin-share">
                                                <LinkedinIcon size={32} round={false} borderRadius={`10`} />
                                            </LinkedinShareButton>
                                            <WhatsappShareButton onClick={()=>trackerShare('WhatsappShareButton')} url={shareurl} className="my-share-button whatsapp-share">
                                                <WhatsappIcon size={32} round={false} borderRadius={`10`} />
                                            </WhatsappShareButton>
                                            {/* <EmailShareButton url='#' onClick={()=> { openSharefrndformModal();trackerShare('SendToFriend')}} className="my-share-button email-share">
                                                <EmailIcon size={32} round={false} borderRadius={`10`} />
                                            </EmailShareButton> */}
                                            </div>
                                        }
            </Col>
          </Row>
          <ContentBlock Content={props?.data?.Job_Detail} />
        </Col>
      </Row>
    </Container>
    </div>
  )
}

export default CareerDetailInfo
