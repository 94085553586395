import React from 'react';
import { Form } from 'react-bootstrap';

const FileUpload = (props) => {
    const { label, file, placeholder, className, ...rest } = props;
    const fileRef = React.createRef(null);
    return (
        <Form.Group className='form-wrap'>
            <Form.Label>{label}</Form.Label>
            <div id="custom-file-label" className={`form-control d-flex flex-column justify-content-center form-control-browsefile custom custom-file ${className}`}
                onClick={() => fileRef.current.click()}>
                <span>{file ? file.name : placeholder}</span></div>
            <Form.Control
                controlId="custom-file"
                type='file'
                className="d-none"
                ref={fileRef}
                {...rest}
                id="custom-file"
            />
        </Form.Group >
    )

}
export default FileUpload;