/**
 * External dependencies
 */
import React, { useState, useEffect, useMemo } from "react"
import axios from "axios";

import { Link } from "gatsby"
import { Row, Col, Container } from "react-bootstrap"
import { currencyFormat } from '@components/common/utils';
/**
 * Internal dependencies
 */
import useHomeProperties from "./use-home-properties"
import { toCapitalize } from "@components/common/utils";
import { convertGatsbyImageNodes } from "../../utils/gatsby-images"
import Card from "../card/card"
import LinkIcon from "../link-icon/link-icon"
import properties from "./static/properties"
import ImageTransform from "@components/common/ggfx-client/module/components/image-transform";
import "./styles/_index.scss"
import Carousel from "../carousel/carousel"
import classNames from "classnames"
import gql from "graphql-tag"
import {
  isIOS,
  isMobile
} from "react-device-detect";
import { useQuery } from "@apollo/client"
import {
  FOR_SALE_PAGE_URL,
  TO_RENT_PAGE_URL,
  CAREERS_PAGE_URL
} from "@components/common/site/constants"
const OtherCareerSlider = (props) => {
  const RELATED_CAREER = gql`
  query GetCareersRelated($URL: String!) {
    careers(publicationState: LIVE
      where: {URL_ne: $URL, Publish: true}
      limit: 3) {
        id
          URL
          Publish
          Job_Title
          Department
          Video_Url
          Branch {
            Office_Name
          }
          Tail_Image{
            url
            alternativeText
          }
            imagetransforms
    }
  }
`
const {
  loading: relatedcareerloading,
  error: relatedcareererror,
  data: relatedcareer,
} = useQuery(RELATED_CAREER, {
  variables: { URL: props.URL },
})
  // const data = useHomeProperties()
  // const images = useMemo(() => convertGatsbyImageNodes(data), [data])
  const propertiesCollection = useMemo(
    () =>
      relatedcareer?.careers?.map((x, i) => {
       
        let processedImages = JSON.stringify({})
        if (x.imagetransforms?.Tail_Image_Transforms) {
          processedImages = x.imagetransforms.Tail_Image_Transforms
        }
        return (
          <Col
          key={x.Job_Title}
          className={classNames("news-land__col", {
            // "d-none d-lg-block": index >= 2,
          })}
        >
          <Card className="card-land">
    
          <Card.Head
            image={x.Tail_Image}
            video={x.Video_Url}
            playBtnOnRight={x.Video_Url ? true : false}
            alt={x.Tail_Image?.alternativeText}
            href={CAREERS_PAGE_URL.alias+'/'+x.URL+'/'}
            onImageHoverZoom
            imgname={"career.Tail_Image.list"} imagetransforms={processedImages} id={x.id}
          />
          <Card.Body>
            <Row className="g-0 align-items-center">
              <Col>
                <h5>
                  <Link to={CAREERS_PAGE_URL.alias+'/'+x.URL}>{x.Job_Title}</Link>
                </h5>
              </Col>
              
              <Col xs="auto">
                <Link to={CAREERS_PAGE_URL.alias+'/'+x.URL} className="link-icon blue-link-hover d-flex align-items-center">
                <div
            className={`link-icon__text fw-bolder fz14 position-relative order-0`}
          >
            Details
          </div>
          <i className={classNames(`icon icon--arrow-up-right`)}></i>
                </Link>
                {/* <LinkIcon text="View Guide" /> */}
               
              </Col>

            </Row>
            <p className="location">{x?.Branch?.map((list, index) => (
                            <>
                              {toCapitalize(list.Office_Name)}
                              {index === x?.Branch?.length - 1
                                ? ""
                                : ", "}
                            </>
                          ))}</p>
          </Card.Body>
        </Card>
        </Col>
        )
      }),
    [relatedcareer?.careers]
  )

  return (
    relatedcareer?.careers?.length > 0 &&
    <div className="home-properties career pt-48 pb-48 pt-120 pb-120">
      <Container className="">
        <h6 className="home-properties__quote fw-bolder fz14 d-inline-block">
          {props.Cap_Title}
        </h6>

        <Row className="align-items-center align-items-lg-start justify-content-lg-between">
          <Col xs={12} lg="auto">
            <h3>{props.Title}</h3>
          </Col>

          
        </Row>
      </Container>
      {/* {JSON.stringify(propItems)} */}
      <div className="news-land">
      {relatedcareer?.careers?.length > 0 &&
        <Carousel
          className="news-land__row flex-column flex-md-row"
          data={propertiesCollection}
          controllers={{ buttons: isMobile && relatedcareer?.careers?.length > 1 ? true : !isMobile && relatedcareer?.careers?.length > 3 ? true : false }}
          isFluidContainer={false}
        />}
      </div>
      
       
    </div>
  )
}

export default OtherCareerSlider
